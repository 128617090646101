import {
  Grid,
  Paper,
  TextField,
  Avatar,
  Button,
  InputAdornment,
  IconButton,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { login, checkAuth } from "../../Services/Https/auth";
import color from "../../Constants/colors";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { clearStorage } from "../../Services/Functions/clearStorage";

const Login = () => {
  const paperStyle = {
    padding: 20,
    backgroundColor: "rgba(255, 255, 255)",
    margin: "0 auto",
  };

  const avatarStyle = {
    backgroundColor: color.white,
    marginInline: "auto",
    marginBottom: "8px",
    border: `2px solid ${color.primary_dark}`,
  };
  const btnstyle = { margin: "8px 0" };
  const textFieldStyle = { margin: "8px 0" };
  const [showPassword, setShowPassword] = useState(false);
  const [, setAuth] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const fetchAuth = async () => {
      try {
        const response = await checkAuth();
        setAuth(response.status === 200);
      } catch (error) {
        setAuth(false);
      }
    };
    fetchAuth();
  }, []);

  useEffect(() => {
    const entraFailed = searchParams.get("entra-failed") === "true";

    if (entraFailed) {
      enqueueSnackbar("Entra ID login failed", { variant: "error" });
      setSearchParams({});
    }
  }, [enqueueSnackbar, searchParams, setSearchParams]);

  // useEffect(() => {
  //   if (auth) {
  //     navigate("/admin");
  //   }
  // }, [auth, navigate]);

  const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleLogin = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();
    setIsLoggingIn(true);
    try {
      await clearStorage(["cw_conversation", "cw_clearence"]);
      const response = await login(username, password);
      if (response && response.cih_token) {
        localStorage.setItem("cih_token", response.cih_token);
        navigate("/");
      } else {
        enqueueSnackbar("Authorization Failed", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Authorization Failed", { variant: "error" });
    }
    setIsLoggingIn(false);
  };

  const handleMicrosoftLogin = () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL}/v1/auth/login-microsoft/`;
  };

  const handleNavigateToPasswordReset = () => {
    navigate("/password-reset-request");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "100vh",
        backgroundColor: color.primary_dark,
      }}
    >
      <Paper
        elevation={0}
        style={paperStyle}
        sx={{
          maxWidth: { xs: "80%", sm: 400, md: 500 },
        }}
      >
        <Grid container direction="column" alignItems="center">
          <Avatar style={avatarStyle}>
            <img
              src="/commo_logo.png"
              alt="Logo"
              style={{ width: "100%", height: "auto" }}
            />
          </Avatar>
          <Typography variant="h4" sx={{ color: color.btn_dark }}>
            Connected Innovation Hub
          </Typography>
        </Grid>
        <form onSubmit={handleLogin}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            style={textFieldStyle}
            required
            value={username}
            onChange={handleChangeUsername}
            disabled={isLoggingIn}
            sx={{
              "& .MuiInputBase-root.MuiOutlinedInput-root input": {
                color: `${color.primary_dark} !important`,
              },
            }}
            InputLabelProps={{
              sx: {
                color: `${color.black} !important`,
              },
            }}
            InputProps={{
              sx: {
                borderColor: `${color.btn_dark} !important`,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${color.btn_dark} !important`,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${color.btn_dark} !important`,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${color.btn_dark} !important`,
                },
              },
            }}
          />
          <TextField
            label="Password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            fullWidth
            style={textFieldStyle}
            required
            value={password}
            onChange={handleChangePassword}
            disabled={isLoggingIn}
            sx={{
              "& .MuiInputBase-root.MuiOutlinedInput-root input": {
                color: `${color.primary_dark} !important`,
              },
            }}
            InputProps={{
              sx: {
                borderColor: `${color.btn_dark} !important`,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${color.btn_dark} !important`,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${color.btn_dark} !important`,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${color.btn_dark} !important`,
                },
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? (
                      <Visibility style={{ color: color.btn_dark }} />
                    ) : (
                      <VisibilityOff style={{ color: color.btn_dark }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: {
                color: `${color.black} !important`,
              },
            }}
          />
          <Typography
            component="button"
            type="button"
            onClick={handleNavigateToPasswordReset}
            sx={{
              display: "block",
              textAlign: "right",
              color: color.primary_dark,
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
              background: "none",
              border: "none",
              cursor: "pointer",
              padding: 0,
              fontSize: "0.875rem",
              width: "100%",
            }}
          >
            Forgot Password?
          </Typography>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={btnstyle}
            fullWidth
            disabled={isLoggingIn}
            sx={{
              backgroundColor: color.btn_dark,
              color: color.white,
              "&:hover": {
                backgroundColor: color.primary_dark,
                color: color.btn_dark,
              },
              textTransform: "none",
            }}
          >
            Sign In
          </Button>
          <Divider
            sx={{
              width: "100%",
              my: 2,
              fontSize: "0.875rem",
            }}
          >
            Or sign in with
          </Divider>
          <Button
            onClick={handleMicrosoftLogin}
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: color.primary_dark,
              color: color.white,
              "&:hover": {
                backgroundColor: color.primary_dark,
                color: color.btn_dark,
              },
              textTransform: "none",
            }}
            startIcon={
              <img
                src="microsoft_logo.png"
                alt="Microsoft Logo"
                style={{ width: 24, height: 24 }}
              />
            }
          >
            Microsoft Entra ID
          </Button>
        </form>
      </Paper>
      <Box
        sx={{
          position: "fixed",
          left: 20,
          bottom: 20,
          zIndex: 1100,
        }}
      >
        <img
          src="logo_big.png"
          alt="Big Logo"
          style={{ maxWidth: "250px", maxHeight: "250px" }}
        />
      </Box>
    </Grid>
  );
};

export default Login;
