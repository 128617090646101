import { Button, Typography, Grid } from "@mui/material";
import { Features } from "../../Constants/Enums/companyStatus";
import color from "../../Constants/colors";
import { enqueueSnackbar } from "notistack";
import { handleAxiosError } from "../../Services/Https/errorHandler";
import { hasPermission } from "../../helpers/helpers";
import { CompanyResponse } from "../../Models/company";
import { evaluationModel } from "../../Models/evaluationModel";
import { useCertification } from "../../Hooks/useCertifications";
import { useQuestionLists } from "../../Hooks/useQuestions";
import BusinessIcon from "@mui/icons-material/Business";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import ClassIcon from "@mui/icons-material/Class";
import QuizIcon from "@mui/icons-material/Quiz";

interface EvaluateButtonProps {
  handleEvaluateClick: (
    questionListId: number,
    certificationId: number
  ) => Promise<void>;
  companyResponse: CompanyResponse | undefined;
  modelOptions: evaluationModel[];
  selectedCompanyId: number | "";
  selectedModelId: number | "";
  certificationId: string;
  questionListId: string;
  isButtonClicked: boolean;
  hasQuestionPermisson: boolean;
}

const EvaluateButton: React.FC<EvaluateButtonProps> = ({
  handleEvaluateClick,
  companyResponse,
  hasQuestionPermisson,
  modelOptions,
  selectedCompanyId,
  selectedModelId,
  certificationId,
  questionListId,
  isButtonClicked,
}) => {
  const { data: certifications } = useCertification();

  const { data: questionLists } = useQuestionLists(
    certificationId ? Number(certificationId) : undefined
  );

  const isDisabled =
    !selectedCompanyId ||
    selectedModelId === "" ||
    !certificationId ||
    (!questionListId && !hasPermission(Features.EVALUATION));

  const handleClick = async () => {
    try {
      await handleEvaluateClick(
        parseInt(questionListId),
        parseInt(certificationId)
      );
    } catch (error: any) {
      const errorMessage = handleAxiosError(error);
      enqueueSnackbar(`Error evaluationg company: ${errorMessage}`, {
        variant: "error",
      });
    }
  };

  const selectedCompany = companyResponse?.companies?.find(
    (company) => company.id === selectedCompanyId
  );
  const companyName = selectedCompany
    ? selectedCompany.name
    : "No company selected";

  const selectedModel = modelOptions?.find(
    (model) => model.model_id === selectedModelId
  );
  const modelName = selectedModel
    ? selectedModel.model_name
    : "No model selected";

  const selectedCertCat = certifications?.find(
    (cert) => cert.id.toString() === certificationId
  );
  const certificationName = selectedCertCat
    ? selectedCertCat.name
    : "No Certification Category selected";

  const selectedQuestionList = questionLists?.question_lists.find(
    (list) => list.id.toString() === questionListId
  );

  const questionListName = selectedQuestionList
    ? selectedQuestionList.name
    : "No Question List selected";

  return (
    <Grid container direction="column" alignItems="center">
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "2rem",
          alignItems: "center",
          flexDirection: "column",
          width: { xs: "90%", sm: "70%", md: "50%" },
          mt: 5,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
            color: color.white,
            marginBottom: "1.5rem",
          }}
        >
          Evaluation
        </Typography>
        <Typography
          sx={{
            fontSize: "1.2rem",
            textAlign: "center",
            color: color.white,
            opacity: "0.7",
          }}
        >
          This is a crucial stage, as the evaluation will be based on these
          selections. Please take your time to carefully review the company,
          certification model, category, and questions to ensure that everything
          is in line with your preferences. Once you confirm that you have
          chosen all the necessary options, you can proceed to the next step.
          It's important to double-check, as any incorrect selection may affect
          the accuracy of your evaluation. Make sure you're satisfied with your
          choices before starting evaluation!
        </Typography>
        <Grid
          container
          sx={{
            marginTop: "10px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid
            container
            sx={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <BusinessIcon
                sx={{
                  fontSize: "2.2rem",
                  color: color.white,
                  display: "inline",
                }}
              />
              <Typography
                sx={{
                  fontSize: "1rem",
                  color: color.btn_dark,
                  display: "inline",
                  textAlign: "center",
                }}
              >
                {companyName}
              </Typography>
            </Grid>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <SmartToyIcon
                sx={{
                  fontSize: "2.2rem",
                  color: color.white,
                  display: "inline",
                }}
              />
              <Typography
                sx={{
                  fontSize: "1rem",
                  color: color.btn_dark,
                  display: "inline",
                  textAlign: "center",
                }}
              >
                {modelName}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: hasQuestionPermisson ? "space-between" : "center",
              width: "100%",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <ClassIcon
                sx={{
                  fontSize: "2.2rem",
                  color: color.white,
                  display: "inline",
                }}
              />
              <Typography
                sx={{
                  fontSize: "1rem",
                  color: color.btn_dark,
                  display: "inline",
                  textAlign: "center",
                }}
              >
                {certificationName}
              </Typography>
            </Grid>
            {hasQuestionPermisson && (
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "50%",
                }}
              >
                <QuizIcon
                  sx={{
                    fontSize: "2.2rem",
                    color: color.white,
                    display: "inline",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "1rem",
                    color: color.btn_dark,
                    display: "inline",
                    textAlign: "center",
                  }}
                >
                  {questionListName}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Button
          sx={{
            backgroundColor: color.btn_dark,
            color: color.white,
            "&:hover": {
              backgroundColor: color.primary_dark,
              color: color.btn_dark,
            },
            marginTop: "2rem",
            opacity: isDisabled ? "0.4" : "1",
          }}
          onClick={handleClick}
          disabled={isDisabled}
          className={isButtonClicked ? "fade-out" : ""}
        >
          <Typography variant="body1" sx={{ padding: 2 }}>
            Evaluate
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default EvaluateButton;
