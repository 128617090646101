import { useState } from "react";
import { useCompanies } from "../../Hooks/useCompanies";
import {
  CircularProgress,
  Grid,
  Input,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Box,
  TablePagination,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import color from "../../Constants/colors";
import "../../Styles/Companies.css";
import { useNavigate } from "react-router-dom";
import { Company } from "../../Models/company";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import BusinessIcon from "@mui/icons-material/Business";
import PlaceIcon from "@mui/icons-material/Place";
import ConstructionIcon from "@mui/icons-material/Construction";

const Companies = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortField, setSortField] = useState<keyof Company>("name");
  const [search, setSearch] = useState("");
  const { data, isLoading } = useCompanies(
    page,
    pageSize,
    sortField,
    sortOrder,
    search
  );

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleSort = (field: keyof Company) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  if (isLoading || !data) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  } else {
    const sortedData = data.companies.sort((a, b) => {
      const aValue = a[sortField] ?? "";
      const bValue = b[sortField] ?? "";

      if (aValue > bValue) {
        return sortOrder === "asc" ? 1 : -1;
      } else if (aValue < bValue) {
        return sortOrder === "asc" ? -1 : 1;
      } else {
        return 0;
      }
    });

    return (
      <Grid container direction="column" alignItems="center">
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          height="100px"
        >
          <Grid item>
            <Typography variant="h2" sx={{ color: color.white }}>
              Companies
            </Typography>
          </Grid>
          {sortedData.length > 0 && (
            <Grid item ml={3}>
              <Tooltip title="Create new company">
                <IconButton
                  onClick={() => {
                    navigate("create");
                  }}
                >
                  <AddCircleOutlineIcon
                    sx={{ color: color.btn_dark, fontsize: "100px" }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
        {sortedData.length > 0 && (
          <>
            <Grid item mt={4}>
              <Input
                style={{
                  color: "white",
                  textAlign: "center",
                }}
                placeholder="Search Companies..."
                inputProps={{ "aria-label": "search" }}
                value={search}
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
              />
            </Grid>

            <Grid
              container
              alignItems="center"
              justifyContent="center"
              marginTop="1em"
            >
              <Typography variant="body1" sx={{ color: color.white }}>
                Sort by name
              </Typography>
              <IconButton
                sx={{
                  fontWeight: "bold",
                  color: color.btn_dark,
                  cursor: "pointer",
                }}
                onClick={() => handleSort(sortField)}
              >
                {sortOrder === "asc" ? <ArrowUpward /> : <ArrowDownward />}
              </IconButton>
            </Grid>
          </>
        )}
        <Grid
          item
          mt={5}
          container
          spacing={2}
          justifyContent="center"
          sx={{ width: "70%" }}
        >
          {sortedData.length > 0 ? (
            sortedData.map((company) => (
              <Grid item key={company.id} xs={12} sm={6} lg={5}>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    backgroundColor: color.white,
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: color.btn_dark,
                      "& .business-icon, & .construction-icon, & .place-icon": {
                        color: color.white,
                      },
                    },
                  }}
                >
                  <CardActionArea
                    onClick={() => navigate(`/company/${company.id}`)}
                  >
                    <CardContent sx={{ flexGrow: 1, minHeight: "120px" }}>
                      <Box sx={{ position: "relative" }}>
                        <Box sx={{ position: "absolute", top: 8, right: 8 }}>
                          <BusinessIcon
                            className="business-icon"
                            sx={{
                              color: color.btn_dark,
                              fontSize: "4.5rem",
                            }}
                          />
                        </Box>
                        <Typography
                          variant="h6"
                          sx={{
                            color: color.primary_dark,
                            fontWeight: "bold",
                            fontSize: "1.7rem",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {company.name}
                        </Typography>
                        {company.industry && (
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "1rem",
                              color: color.primary_dark,
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "3px",
                            }}
                          >
                            <ConstructionIcon
                              className="construction-icon"
                              sx={{
                                fontSize: "1.2rem",
                                marginRight: "3px",
                                color: color.btn_dark,
                              }}
                            />
                            {company.industry}
                          </Typography>
                        )}
                        {company.location && (
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "0.9rem",
                              color: color.primary_dark,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <PlaceIcon
                              className="place-icon"
                              sx={{
                                fontSize: "1.2rem",
                                marginRight: "3px",
                                color: color.btn_dark,
                              }}
                            />
                            {company.location}
                          </Typography>
                        )}
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="h4"
                sx={{ color: color.white, textAlign: "center" }}
              >
                It looks like you don't have any companies yet. Time to create
                your first one!
              </Typography>
              <Button
                sx={{
                  backgroundColor: color.btn_dark,
                  marginTop: "2rem",
                  "&:hover": {
                    backgroundColor: `${color.primary_dark}`,
                    color: `${color.btn_dark}`,
                  },
                }}
                onClick={() => navigate("/company/create")}
              >
                Create company
              </Button>
            </Grid>
          )}
        </Grid>
        {sortedData.length > 0 && (
          <Grid item mb={10}>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={data.total}
              rowsPerPage={pageSize}
              page={page - 1}
              onPageChange={handleChangePage}
            />
          </Grid>
        )}
      </Grid>
    );
  }
};

export default Companies;
