import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { TableContainer, TablePagination, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import SearchInput from "./SearchInput";
import ActionButton from "./ActionButton";
import QuestionListTable from "./QuestionListTable";
import DeleteQuestionListDialog from "./DeleteQuestionListDialog";
import DefaultQuestionListDialog from "./DefaultQuestionListDialog";
import {
  fetchQuestionLists,
  deleteQuestionList,
  setDefaultQuestionList,
  importQuestionList,
  copyQuestionList,
  exportQuestionList,
  shareQuestionList,
} from "../../Services/Https/questions";
import { useSortableData } from "../../Hooks/useSortableData";
import { QuestionItem } from "../../Models/question";
import { useToggleArchiveStatus } from "../../Hooks/useQuestions";
import { downloadJSON } from "../../helpers/helpers";
import { handleAxiosError } from "../../Services/Https/errorHandler";
import color from "../../Constants/colors";

const MyQuestions: React.FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const [data, setData] = useState<QuestionItem[]>([]);
  const [questionListCount, setQuestionListCount] = useState(0);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [openDefaultDialog, setOpenDefaultDialog] = useState(false);
  const [selectedList, setSelectedList] = useState<QuestionItem | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const toggleArchiveMutation = useToggleArchiveStatus();

  const { sortedItems, sortField, sortOrder, handleSort } =
    useSortableData<QuestionItem>(data, "name");

  const loadData = useCallback(async () => {
    try {
      const response = await fetchQuestionLists(
        page,
        rowsPerPage,
        searchTerm,
        undefined
      );
      setData(response.question_lists);
      setQuestionListCount(response.total);
    } catch (error) {
      enqueueSnackbar("Failed to load question lists", { variant: "error" });
    }
  }, [page, rowsPerPage, searchTerm, enqueueSnackbar]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleDelete = async (id: number) => {
    try {
      await deleteQuestionList(id);
      const newData = data.filter((item) => item.id !== id);
      setData(newData);
      setQuestionListCount((prevCount) => prevCount - 1);
      enqueueSnackbar("Question list deleted successfully.", {
        variant: "success",
      });

      if (newData.length === 0 && page > 1) {
        setPage(page - 1);
      } else {
        const loadData = async () => {
          const response = await fetchQuestionLists(
            page,
            rowsPerPage,
            searchTerm,
            undefined
          );
          setData(response.question_lists);
        };
        loadData().catch((error) => {
          enqueueSnackbar("Error loading question lists.", {
            variant: "error",
          });
        });
      }
    } catch (error) {
      enqueueSnackbar("An error occurred while deleting the question list.", {
        variant: "error",
      });
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleEdit = (item: any) => {
    navigate(`/question_list`, {
      state: {
        item,
        action: "edit",
      },
    });
  };

  const handleCopy = async (item: any) => {
    try {
      const response = await copyQuestionList(item.id);
      await loadData();
      const responseQuestionLists = await fetchQuestionLists(
        page,
        rowsPerPage,
        searchTerm,
        undefined
      );

      const newQuestionItem = responseQuestionLists.question_lists.find(
        (questionList) => questionList.id === response.question_list_id
      );

      enqueueSnackbar("Question list copied successfully.", {
        variant: "success",
      });

      navigate(`/questions_list`, {
        state: {
          item: newQuestionItem,
          action: "copy",
        },
      });
    } catch (error) {
      enqueueSnackbar("An error occurred while copying the question list.", {
        variant: "error",
      });
    }
  };

  const handleToggleArchive = async (id: number, archived: boolean) => {
    try {
      await toggleArchiveMutation.mutateAsync(id);
      setData((prevData) =>
        prevData.map((item) =>
          item.id === id ? { ...item, archived: !item.archived } : item
        )
      );
      enqueueSnackbar(
        `Item ${archived ? "unarchived" : "archived"} successfully`,
        { variant: "success" }
      );
    } catch (error) {
      enqueueSnackbar("Error toggling archive status", { variant: "error" });
    }
  };

  const handleExportAdminList = async (id: number, name: string) => {
    try {
      const response = await exportQuestionList(id);
      downloadJSON(response, `${name}.json`);
      enqueueSnackbar(`Question list exported successfully.`, {
        variant: "success",
      });
    } catch (error) {
      const errorMessage = handleAxiosError(error);
      enqueueSnackbar(`Error exporting question list: ${errorMessage}`, {
        variant: "error",
      });
    }
  };

  const handleConfirmDefault = async () => {
    if (selectedList) {
      try {
        await setDefaultQuestionList(selectedList.id, selectedList.category.id);
        enqueueSnackbar("Default list set successfully", {
          variant: "success",
        });
        loadData();
      } catch (error) {
        enqueueSnackbar("Failed to set default list", { variant: "error" });
      }
      setOpenDefaultDialog(false);
    }
  };

  const handleImport = async (file: File) => {
    try {
      setIsLoading(true);
      await importQuestionList(file);
      enqueueSnackbar("File imported successfully", { variant: "success" });
      loadData();
    } catch (error) {
      enqueueSnackbar("Failed to import file", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleShare = async (id: number) => {
    try {
      await shareQuestionList(id);
      enqueueSnackbar("Question list shared successfully.", {
        variant: "success",
      });
      loadData();
    } catch (error) {
      enqueueSnackbar("Failed to share the question list.", {
        variant: "error",
      });
    }
  };

  const handleOpenDeleteDialog = (id: number) => {
    setCurrentId(id);
    setConfirmationDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setConfirmationDialogOpen(false);
    setCurrentId(null);
  };

  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "20px",
        width: "100%",
      }}
    >
      <Typography
        variant="h4"
        component="h2"
        sx={{ color: color.white, marginBottom: "20px" }}
      >
        My Question Lists
      </Typography>
      <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <ActionButton
        onCreate={() => navigate(`/question_list`)}
        onImport={handleImport}
        isLoading={isLoading}
      />
      <TableContainer
        sx={{
          width: "80%",
          overflowX: "auto",
        }}
      >
        <QuestionListTable
          data={sortedItems}
          sortField={sortField}
          sortOrder={sortOrder}
          handleSort={handleSort}
          onDelete={(id) => handleOpenDeleteDialog(id)}
          onEdit={handleEdit}
          onCopy={handleCopy}
          onToggleArchive={handleToggleArchive}
          onExport={handleExportAdminList}
          onShare={handleShare}
          onSetDefault={(item) => {
            setSelectedList(item);
            setOpenDefaultDialog(true);
          }}
        />
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={questionListCount}
        page={page - 1}
        onPageChange={(event, newPage) => setPage(newPage + 1)}
        rowsPerPage={rowsPerPage}
      />
      <DeleteQuestionListDialog
        open={confirmationDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={async () => {
          if (currentId) {
            await handleDelete(currentId);
            handleCloseDeleteDialog();
          }
        }}
      />

      <DefaultQuestionListDialog
        open={openDefaultDialog}
        onClose={() => setOpenDefaultDialog(false)}
        onConfirm={handleConfirmDefault}
        selectedList={selectedList}
      />
    </Grid>
  );
};

export default MyQuestions;
