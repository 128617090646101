import { useState } from "react";
import { Grid, TextField } from "@mui/material";
import color from "../../Constants/colors";
import { QuestionListFull } from "../../Models/question";
import CategoryForm from "./CategoryForm";
import { enqueueSnackbar } from "notistack";

export interface ListNameFormProps {
  isEdit: boolean;
  questionListFull: QuestionListFull;
  setQuestionListFull: React.Dispatch<React.SetStateAction<QuestionListFull>>;
  isSubmitting: boolean;
}

const ListNameForm: React.FC<ListNameFormProps> = ({
  isEdit,
  questionListFull,
  setQuestionListFull,
  isSubmitting,
}) => {
  const [isListNameCreated, setIsListNameCreated] = useState<boolean>(false);
  const [isSnackbarShown, setIsSnackbarShown] = useState<boolean>(false);

  const hasCategory =
    questionListFull.categories.length > 0 &&
    questionListFull.categories[0].category_name !== "";

  const handleChangeListName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value.length > 100) {
      if (!isSnackbarShown) {
        enqueueSnackbar("List name cannot be longer than 100 characters.", {
          variant: "warning",
        });
        setIsSnackbarShown(true);
      }
      return;
    }

    if (isSnackbarShown) {
      setIsSnackbarShown(false);
    }

    setIsListNameCreated(true);
    setQuestionListFull((prev) => ({
      ...prev,
      list_name: value,
    }));
  };

  return (
    <Grid>
      <TextField
        label={isEdit ? "Edit Question List Name" : "Question List Name"}
        variant="outlined"
        fullWidth
        sx={{ marginBottom: 2 }}
        value={questionListFull.list_name}
        onChange={handleChangeListName}
        disabled={isSubmitting}
        InputLabelProps={{
          style: { color: color.white },
        }}
      />
      {(isListNameCreated || hasCategory || isEdit) && (
        <CategoryForm
          isEdit={isEdit}
          questionListFull={questionListFull}
          setQuestionListFull={setQuestionListFull}
        />
      )}
    </Grid>
  );
};

export default ListNameForm;
