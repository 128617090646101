import React from "react";
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import color from "../../Constants/colors";
import { evaluationModel } from "../../Models/evaluationModel";

interface SelectEvaluationModelProps {
  selectedModelId: number | "";
  modelOptions: evaluationModel[];
  handleModelChange: (modelId: number) => void;
  isEvaluateLoading: boolean;
  useEvaluationModelLoading: boolean;
}

const SelectEvaluationModel: React.FC<SelectEvaluationModelProps> = ({
  selectedModelId,
  modelOptions,
  handleModelChange,
}) => {
  const handleCardClick = (modelId: number) => {
    handleModelChange(modelId);
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "2rem",
          alignItems: "center",
          flexDirection: "column",
          width: { xs: "90%", sm: "70%", md: "50%" },
          mt: 5,
        }}
      >
        <Typography
          variant="h3"
          sx={{ color: color.white, marginBottom: "1.5rem" }}
        >
          Select Evaluation Model
        </Typography>
        <Typography
          sx={{
            fontSize: "1.2rem",
            textAlign: "center",
            color: color.white,
            opacity: "0.7",
          }}
        >
          Please select the Evaluation Model that will be used to process the
          evaluation. This model defines the type of language model utilized and
          also specifies which provider manages the company's information. By
          selecting a model, you determine the methodology and parameters that
          guide the evaluation process, ensuring that the data is processed
          according to the specific requirements of the analysis. The chosen
          model will allow the system to apply the appropriate methods and
          tools, making sure that the evaluation aligns with the company's
          standards and information management protocols.
        </Typography>
      </Grid>

      <Grid container spacing={2} justifyContent="center" sx={{ width: "70%" }}>
        {modelOptions.map((model) => (
          <Grid item key={model.model_id} xs={12} sm={6} md={4}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor:
                  selectedModelId === model.model_id
                    ? color.btn_dark
                    : color.white,
                transition: "background-color 0.3s ease",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: color.btn_dark,
                },
                boxShadow: selectedModelId === model.model_id ? 3 : 1,
              }}
              onClick={() => handleCardClick(model.model_id)}
            >
              <CardActionArea>
                <CardContent sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ flexGrow: 1, textAlign: "left" }}>
                    <Tooltip
                      title={
                        model.model_name.length > 19 ? model.model_name : ""
                      }
                      arrow
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "1rem",
                          color:
                            selectedModelId === model.model_id
                              ? color.primary_dark
                              : color.primary_dark,
                          fontWeight: "bold",
                        }}
                      >
                        {model.model_name.length > 19
                          ? `${model.model_name.slice(0, 19)}...`
                          : model.model_name}
                      </Typography>
                    </Tooltip>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default SelectEvaluationModel;
