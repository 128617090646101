import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import color from "../../../Constants/colors";
import { useCallback } from "react";
import FileList from "./FileList";

interface FileUploadProps {
  uploadedFiles: File[];
  page: number;
  rowsPerPage: number;
  allowedExtensions: string[];
  handleRemoveFile: (fileName: string) => void;
  handleOpenFile: (file: File) => void;
  setUploadedFiles: React.Dispatch<React.SetStateAction<File[]>>;
  disabled?: boolean;
}

const FileUploader: React.FC<FileUploadProps> = ({
  uploadedFiles,
  setUploadedFiles,
  page,
  rowsPerPage,
  allowedExtensions,
  handleRemoveFile,
  handleOpenFile,
  disabled = false,
}) => {
  const handleFileChange = useCallback(
    (files: File[]) => {
      if (files) {
        const filesArray = Array.from(files).filter((newFile) => {
          const parts = newFile.name.split(".");
          const fileExtension =
            parts.length > 1 ? parts[parts.length - 1].toLowerCase() : null;

          const isAllowedFileType = fileExtension
            ? allowedExtensions.includes(fileExtension)
            : false;
          const isDuplicate = uploadedFiles.some(
            (existingFile) =>
              existingFile.name === newFile.name &&
              existingFile.size === newFile.size
          );
          return isAllowedFileType && !isDuplicate;
        });

        if (filesArray.length !== files.length) {
          alert(
            "Duplicate files or files with unsupported formats were not added."
          );
        }

        setUploadedFiles((prevFiles) => [...prevFiles, ...filesArray]);
      }
    },
    [uploadedFiles, setUploadedFiles, allowedExtensions]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      handleFileChange(acceptedFiles);
    },
    [handleFileChange]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled,
  });

  return (
    <>
      <Box
        {...getRootProps()}
        onClick={() => document.getElementById("hidden-file-input")?.click()}
        sx={{
          width: "100%",
          border: "2px dashed grey",
          cursor: "pointer",
          display: "flex",
          alignItems: uploadedFiles.length === 0 ? "center" : "center",
          justifyContent: uploadedFiles.length === 0 ? "center" : "flex-start",
          flexDirection: "column",
          borderRadius: 2,
          padding: uploadedFiles.length > 0 ? 4 : 2,
        }}
      >
        {uploadedFiles.length !== 0 && (
          <Box sx={{ flex: 1, overflowY: "auto" }}>
            <FileList
              files={uploadedFiles.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )}
              onRemoveFile={handleRemoveFile}
              onOpenFile={handleOpenFile}
            />
          </Box>
        )}
        <input
          {...getInputProps()}
          id="hidden-file-input"
          disabled={disabled}
        />

        {uploadedFiles.length === 0 && (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ width: { xs: "85%", md: "100%" } }}
          >
            <img
              src="/upload.png"
              alt="upload"
              style={{
                width: "60%",
                opacity: isDragActive ? 0.4 : 0.8,
                filter: "drop-shadow(2px 2px 5px rgba(0,0,0,0.5))",
                transition: "opacity 0.3s ease",
              }}
            />
            <Tooltip title={`Allowed types: ${allowedExtensions.join(", ")}`}>
              <Typography
                variant="body2"
                sx={{
                  color: color.primary_light,
                  fontStyle: "italic",
                  opacity: 0.6,
                  margin: 2,
                }}
              >
                Allowed types: {allowedExtensions.join(", ")}
              </Typography>
            </Tooltip>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default FileUploader;
