import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
  Tooltip,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IosShareIcon from "@mui/icons-material/IosShare";
import HardwareRoundedIcon from "@mui/icons-material/HardwareRounded";
import HardwareOutlinedIcon from "@mui/icons-material/HardwareOutlined";
import ReplyIcon from "@mui/icons-material/Reply";
import color from "../../Constants/colors";
import { QuestionItem } from "../../Models/question";
import { useNavigate } from "react-router-dom";
import { getCurrentWorkspace } from "../../Services/Https/workspace";
import { getMySelf } from "../../Services/Https/user";
import { hasPermission, hasRole } from "../../helpers/helpers";
import { Features, UserRole } from "../../Constants/Enums/companyStatus";
import { useCertifications } from "../../Hooks/useCertifications";
import PolicyIcon from "@mui/icons-material/Policy";

interface QuestionListTableProps {
  data: QuestionItem[];
  sortField: keyof QuestionItem;
  sortOrder: "asc" | "desc";
  handleSort: (field: keyof QuestionItem) => void;
  onDelete: (id: number) => void;
  onEdit: (item: QuestionItem) => void;
  onCopy: (item: QuestionItem) => void;
  onToggleArchive: (id: number, archived: boolean) => void;
  onExport: (id: number, name: string) => void;
  onSetDefault: (item: QuestionItem) => void;
  onShare: (id: number) => void;
}

const QuestionListTable: React.FC<QuestionListTableProps> = ({
  data,
  sortField,
  sortOrder,
  handleSort,
  onDelete,
  onEdit,
  onCopy,
  onToggleArchive,
  onExport,
  onSetDefault,
  onShare,
}) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [, setError] = useState<string | null>(null);
  const [workspace, setWorkspace] = useState<any>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState<QuestionItem | null>(null);
  const { data: certifications } = useCertifications();
  const digitalPerformanceCertifications = certifications?.filter(
    (cert) => cert.digital_performance === true
  );

  useEffect(() => {
    const fetchWorkspace = async () => {
      try {
        const currentWorkspace = await getCurrentWorkspace();
        setWorkspace(currentWorkspace.workspace);
      } catch (error) {
        console.error("Error fetching current workspace:", error);
      }
    };

    fetchWorkspace();
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await getMySelf();
        setUser(userData);
      } catch (err) {
        setError("Error fetching user info.");
      }
    };

    fetchUser();
  }, []);

  const handleShareClick = (item: QuestionItem) => {
    setSelectedItem(item);
    setOpenDialog(true);
  };

  const handleConfirmShare = () => {
    if (selectedItem) {
      onShare(selectedItem.id);
      setOpenDialog(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                color: color.white,
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => handleSort("name")}
            >
              Name
              {sortField === "name" && (
                <TableSortLabel active direction={sortOrder} />
              )}
            </TableCell>
            <TableCell
              sx={{
                color: color.white,
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => handleSort("category")}
            >
              Evaluation Category
              {sortField === "category" && (
                <TableSortLabel active direction={sortOrder} />
              )}
            </TableCell>
            <TableCell sx={{ color: color.white, fontWeight: "bold" }}>
              Actions
            </TableCell>
            <TableCell sx={{ color: color.white, fontWeight: "bold" }}>
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow
              key={item.id}
              sx={{
                backgroundColor: item.default
                  ? color.backgroungOfMyQuestionTable
                  : "transparent",
                "&:hover": { backgroundColor: color.primary_dark },
              }}
            >
              <TableCell
                sx={{
                  color: color.white,
                  fontWeight: item.default ? "bold" : "normal",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/question_list/${item.id}`)}
              >
                <Box>
                  <Typography variant="body1">{item.name}</Typography>

                  <Typography
                    variant="body2"
                    sx={{
                      color: color.white,
                      opacity: 0.7,
                      fontSize: "0.8rem",
                      marginTop: "4px",
                    }}
                  >
                    Created by: {item.created_by?.name || "Unknown"}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell sx={{ color: color.white }}>
                {item.category.name}
                {digitalPerformanceCertifications?.some(
                  (cert) =>
                    cert.id === item.category.id &&
                    cert.digital_performance === true
                ) && (
                  <PolicyIcon
                    fontSize="small"
                    sx={{
                      verticalAlign: "bottom",
                      marginLeft: "4px",
                      color: color.btn_dark,
                    }}
                  />
                )}
              </TableCell>
              <TableCell sx={{ color: color.white }}>
                <Tooltip
                  title={
                    item.default || item.in_use
                      ? "Cannot edit this list"
                      : "Edit"
                  }
                >
                  <span>
                    <IconButton
                      sx={{
                        color: color.btn_dark,
                        opacity: item.default || item.in_use ? 0.4 : 1,
                      }}
                      onClick={() => onEdit(item)}
                      disabled={item.default || item.in_use}
                    >
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  title={
                    item.default || item.in_use
                      ? "Cannot delete this list"
                      : "Delete"
                  }
                >
                  <span>
                    <IconButton
                      sx={{
                        color: color.red,
                        opacity: item.default || item.in_use ? 0.4 : 1,
                      }}
                      onClick={() => onDelete(item.id)}
                      disabled={item.default || item.in_use}
                    >
                      <DeleteIcon sx={{ color: color.red }} />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Copy">
                  <span>
                    <IconButton
                      sx={{ color: color.btn_dark }}
                      onClick={() => onCopy(item)}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  title={
                    item.archived ? "Unarchive this list" : "Archive this list"
                  }
                >
                  <span>
                    <IconButton
                      onClick={() => onToggleArchive(item.id, item.archived)}
                      sx={{
                        color: color.btn_dark,
                        opacity: item.default ? 0.4 : 1,
                      }}
                      disabled={item.default}
                    >
                      {item.archived ? <UnarchiveIcon /> : <ArchiveIcon />}
                    </IconButton>
                  </span>
                </Tooltip>
                {hasRole(UserRole.ADMIN) && (
                  <>
                    <Tooltip
                      title={
                        item.default
                          ? "This is already the default list"
                          : "Set as Default"
                      }
                    >
                      <span>
                        <IconButton
                          onClick={() => onSetDefault(item)}
                          sx={{
                            color: color.btn_dark,
                            opacity: item.default || item.archived ? 0.4 : 1,
                          }}
                          disabled={item.default || item.archived}
                        >
                          {item.default ? (
                            <HardwareRoundedIcon />
                          ) : (
                            <HardwareOutlinedIcon />
                          )}
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Export question list as JSON">
                      <span>
                        <IconButton
                          sx={{ color: color.btn_dark }}
                          onClick={() => onExport(item.id, item.name)}
                        >
                          <IosShareIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </>
                )}
                {hasPermission(Features.EVALUATION) && (
                  <Tooltip title="Export question list as JSON">
                    <span>
                      <IconButton
                        sx={{ color: color.btn_dark }}
                        onClick={() => onExport(item.id, item.name)}
                      >
                        <IosShareIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                {item.created_by.id === user?.id &&
                  workspace !== null &&
                  item.default === false &&
                  item.workspace === null && (
                    <Tooltip title="Share question list">
                      <span>
                        <IconButton
                          sx={{ color: color.btn_dark }}
                          onClick={() => handleShareClick(item)}
                        >
                          <ReplyIcon sx={{ transform: "scaleX(-1)" }} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
              </TableCell>
              <TableCell sx={{ color: color.white }}>
                {item.archived ? "Archived" : "Active"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle sx={{ color: color.primary_dark }}>
          Confirm Share
        </DialogTitle>
        <DialogContent>
          <Typography variant="caption" sx={{ color: color.primary_dark }}>
            Are you sure you want to share this question list with your
            workspace? This action is irreversible.
          </Typography>
          <Typography
            sx={{
              color: color.primary_dark,
              fontStyle: "italic",
              fontSize: "0.8rem",
              opacity: 0.8,
            }}
          >
            This list will be copied to your workspace, allowing others to work
            with it as they wish.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            sx={{
              backgroundColor: color.btn_dark,
              color: color.white,
              marginBottom: "10px",
              marginTop: "10px",
              "&:hover": {
                backgroundColor: color.primary_dark,
                color: color.btn_dark,
              },
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmShare}
            sx={{
              backgroundColor: color.btn_dark,
              color: color.white,
              marginBottom: "10px",
              marginTop: "10px",
              "&:hover": {
                backgroundColor: color.primary_dark,
                color: color.btn_dark,
              },
            }}
            variant="outlined"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QuestionListTable;
