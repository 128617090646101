import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useState, useEffect } from "react";
import { createUser } from "../../Services/Https/user";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Features } from "../../Constants/Enums/companyStatus";
import color from "../../Constants/colors";
import { ModelLevel } from "../../Constants/Enums/companyStatus";
import { Certification } from "../../Models/certification";
import { getCertCategories } from "../../Services/Https/certifications";
import { handleAxiosError } from "../../Services/Https/errorHandler";
import { getAllWorkspaces } from "../../Services/Https/workspace";
import FeaturesComponent from "./FeaturesComponent";
import WorkspaceSelector from "./WorkspaceSelector";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const CreateUserPage = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    surname: "",
    daily_limit: 1,
    max_company_limit: 1,
    model_level: ModelLevel.BASIC,
    cert_categories: [] as Certification[],
    is_sso: false,
    workspace_id: "" as string | number,
    features: [] as number[],
  });
  const [certCategories, setCertCategories] = useState<Certification[]>([]);
  const navigate = useNavigate();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [workspaces, setWorkspaces] = useState<{ id: number; name: string }[]>(
    []
  );

  const [workspaceId, setWorkspaceId] = useState<string | number>("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const fetchCertCategories = async () => {
      try {
        const categories = await getCertCategories();
        setCertCategories(categories);
      } catch (error) {
        const errorMessage = handleAxiosError(error);
        enqueueSnackbar(
          `Error fetching certification categories: ${errorMessage}`,
          { variant: "error" }
        );
      }
    };

    const fetchWorkspaces = async () => {
      try {
        const data = await getAllWorkspaces(1, 50);
        setWorkspaces(data.workspaces);
      } catch (error) {
        enqueueSnackbar("Error fetching workspaces.", { variant: "error" });
      }
    };

    fetchWorkspaces();
    fetchCertCategories();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    const { name, value } = e.target;

    if (name === "workspace_id" && !value) {
      setUser((prevUser) => ({
        ...prevUser,
        [name]: workspaces.length > 0 ? workspaces[0].id : "",
      }));
      return;
    }

    if (name === "is_sso") {
      setUser({
        ...user,
        [name]: value,
      });
    } else {
      setUser({
        ...user,
        [name]: value,
      });
    }
  };

  const handleCertCategoriesChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    category: Certification
  ) => {
    const isChecked = event.target.checked;

    setUser((prevUser) => {
      const updatedCategories = isChecked
        ? [...prevUser.cert_categories, category]
        : prevUser.cert_categories.filter((c) => c.id !== category.id);

      return {
        ...prevUser,
        cert_categories: updatedCategories,
      };
    });
  };

  const handleSubmit = async () => {
    if (!emailRegex.test(user.email)) {
      enqueueSnackbar("Please enter a valid email address.", {
        variant: "warning",
      });
      return;
    }

    if (user.name.length < 2) {
      enqueueSnackbar("Name must be at least 2 characters long.", {
        variant: "warning",
      });
      return;
    }

    if (user.surname.length < 2) {
      enqueueSnackbar("Surname must be at least 2 characters long.", {
        variant: "warning",
      });
      return;
    }

    if (!user.is_sso && user.password.length < 7) {
      enqueueSnackbar("Password must be at least 7 characters long.", {
        variant: "warning",
      });
      return;
    }
    const workspaceIdToSend: number | null =
      workspaceId === "" || isNaN(Number(workspaceId))
        ? null
        : Number(workspaceId);
    const cert_category_ids = user.cert_categories.map((cert) => cert.id);
    try {
      await createUser(
        user.name,
        user.surname,
        user.is_sso ? null : user.password,
        user.email,
        user.daily_limit,
        user.max_company_limit,
        user.model_level,
        cert_category_ids,
        user.is_sso,
        workspaceIdToSend,
        user.features
      );
      enqueueSnackbar("User created successfully", { variant: "success" });
      navigate("/users");
    } catch (error) {
      const errorMessage = handleAxiosError(error);
      enqueueSnackbar(`Error creating user: ${errorMessage}`, {
        variant: "error",
      });
    }
  };

  return (
    <Container maxWidth="md">
      <Grid item>
        <Typography variant="h3" sx={{ color: color.white }}>
          Create User
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Name"
            name="name"
            value={user.name}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              sx: {
                color: color.white,
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Surname"
            name="surname"
            value={user.surname}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              sx: {
                color: color.white,
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email"
            name="email"
            value={user.email}
            onChange={(e) => {
              const email = e.target.value.toLowerCase();
              setUser((prevUser) => ({
                ...prevUser,
                email: email,
              }));
            }}
            fullWidth
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              sx: { color: color.white },
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={user.is_sso}
                onChange={(e) =>
                  setUser({
                    ...user,
                    is_sso: e.target.checked,
                    password: "",
                  })
                }
                sx={{ color: color.white }}
              />
            }
            label={
              <Typography sx={{ color: color.white }}>
                Use Single Sign-On (SSO){" "}
              </Typography>
            }
          />
        </Grid>
        {!user.is_sso && (
          <Grid item xs={12}>
            <TextField
              label="Password"
              name="password"
              value={user.password}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <Button
                    onClick={() => setShowPassword(!showPassword)}
                    sx={{
                      minWidth: 0,
                      padding: 0,
                      marginRight: "8px",
                    }}
                    title={showPassword ? "Hide Password" : "Show Password"}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </Button>
                ),
              }}
              InputLabelProps={{
                sx: { color: color.white },
                shrink: true,
              }}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <TextField
            label="Daily Limit"
            name="daily_limit"
            value={user.daily_limit}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              sx: { color: color.white },
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Max Company Limit"
            name="max_company_limit"
            value={user.max_company_limit}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              sx: { color: color.white },
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: color.btn_dark,
                },
              }}
            >
              <Typography variant="body1" sx={{ color: color.white }}>
                AI Model Level
              </Typography>
            </InputLabel>
            <Select
              label="AI Model Level"
              name="model_level"
              value={user.model_level}
              onChange={handleChange}
              fullWidth
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: color.primary_dark,
                    opacity: 0.95,
                  },
                },
              }}
            >
              <MenuItem value={ModelLevel.BASIC}>
                <Typography sx={{ color: color.white }}>Basic</Typography>
              </MenuItem>
              <MenuItem value={ModelLevel.ENTRY}>
                <Typography sx={{ color: color.white }}>Entry</Typography>
              </MenuItem>
              <MenuItem value={ModelLevel.FULL}>
                <Typography sx={{ color: color.white }}>Full</Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <WorkspaceSelector
          workspaces={workspaces}
          workspaceId={workspaceId}
          setWorkspaceId={setWorkspaceId as any}
        />

        <FeaturesComponent
          selectedFeatures={user.features}
          setSelectedFeatures={(features) => {
            setUser((prevUser) => ({
              ...prevUser,
              features:
                typeof features === "function"
                  ? features(prevUser.features)
                  : features,
            }));
          }}
        />

        {user.features.includes(Features.QUESTION_LISTS) ||
        user.features.includes(Features.EVALUATION) ? (
          <Grid item xs={12} mt={1}>
            <Typography
              sx={{
                color: color.white,
                borderBottom: "2px solid white",
                lineHeight: "32px",
              }}
            >
              Choose Certification Categories
            </Typography>
            <FormControl component="fieldset" fullWidth margin="normal">
              <FormGroup>
                {certCategories.map((category) => (
                  <FormControlLabel
                    key={category.id}
                    control={
                      <Checkbox
                        checked={user.cert_categories.some(
                          (c) => c.id === category.id
                        )}
                        onChange={(event) =>
                          handleCertCategoriesChange(event, category)
                        }
                        sx={{ color: color.white }}
                      />
                    }
                    label={
                      <Typography sx={{ color: color.white }}>
                        {category.name}
                      </Typography>
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <Tooltip
            title={"Please fill out all fields"}
            disableHoverListener={
              !(
                !user.name ||
                !user.surname ||
                !user.daily_limit ||
                !user.email ||
                !user.max_company_limit ||
                (!user.is_sso && !user.password)
              )
            }
          >
            <span>
              <Button
                onClick={handleSubmit}
                disabled={
                  !user.name ||
                  !user.surname ||
                  !user.daily_limit ||
                  !user.email ||
                  !user.max_company_limit ||
                  (!user.is_sso && !user.password)
                }
                variant="contained"
                fullWidth
                sx={{
                  marginBottom: 10,
                  backgroundColor: color.btn_dark,
                  color: color.white,
                  "&:hover": {
                    backgroundColor: color.primary_dark,
                    color: color.btn_dark,
                  },
                }}
              >
                Create
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CreateUserPage;
