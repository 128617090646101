import React from "react";
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  Typography,
  LinearProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import color from "../../Constants/colors";

const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: color.btn_dark,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: color.btn_dark,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: color.white,
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

interface StepperComponentProps {
  currentStep: number;
  steps: string[];
}

const StepperComponent: React.FC<StepperComponentProps> = ({
  currentStep,
  steps,
}) => {
  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
    >
      <Grid
        item
        sx={{ width: "100%", marginTop: "2rem", marginBottom: "2rem" }}
      >
        <LinearProgress
          variant="determinate"
          value={(currentStep / (steps.length - 1)) * 100}
          sx={{
            height: 5,
            borderRadius: 5,
            backgroundColor: color.white,
            "& .MuiLinearProgress-bar": {
              backgroundColor: color.btn_dark,
            },
          }}
        />
      </Grid>
      <Grid
        container
        justifyContent="center"
        direction="column"
        alignItems="center"
      >
        <Stepper
          activeStep={currentStep}
          alternativeLabel
          connector={<QontoConnector />}
        >
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel>
                <Typography sx={{ fontSize: "1rem", color: color.white }}>
                  {step}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  );
};

export default StepperComponent;
