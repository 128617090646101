import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import color from "../../Constants/colors";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  getAllWorkspaces,
  deleteWorkspace,
} from "../../Services/Https/workspace";
import { Workspace } from "../../Models/workspace";
import { enqueueSnackbar } from "notistack";
import ConfirmationDialog from "./ConfiramtionDialog";

const ViewWorkspaces = () => {
  const navigate = useNavigate();
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace | null>(
    null
  );
  const [page, setPage] = useState(1);
  const [totalWorkspaces, setTotalWorkspaces] = useState(0);
  const rowsPerPage = 10;

  useEffect(() => {
    const fetchWorkspaces = async () => {
      try {
        const data = await getAllWorkspaces(page, rowsPerPage);
        setWorkspaces(data.workspaces);
        setTotalWorkspaces(data.total);
      } catch (error) {
        enqueueSnackbar("Failed to fetch workspaces.", { variant: "error" });
      }
    };

    fetchWorkspaces();
  }, [page]);
  const parseNamespaces = (namespaces: any): string[] => {
    if (Array.isArray(namespaces)) {
      return namespaces;
    }
    if (typeof namespaces === "string") {
      try {
        return JSON.parse(namespaces.replace(/'/g, '"'));
      } catch {
        enqueueSnackbar("Failed to parse namespaces.", { variant: "error" });
      }
    }
    return [];
  };

  const handleEdit = (workspace: Workspace) => {
    const parsedNamespaces = parseNamespaces(workspace.namespaces);
    navigate("/workspace/create", {
      state: {
        workspace: {
          ...workspace,
          namespaces: parsedNamespaces,
        },
      },
    });
  };

  const handleDeleteClick = (workspace: Workspace) => {
    setSelectedWorkspace(workspace);
    setOpenDialog(true);
  };

  const handleDeleteConfirm = async (workspaceName: string) => {
    if (selectedWorkspace && selectedWorkspace.name === workspaceName) {
      try {
        await deleteWorkspace(selectedWorkspace.id);
        setWorkspaces((prev) =>
          prev.filter((workspace) => workspace.id !== selectedWorkspace.id)
        );
        enqueueSnackbar("Workspace deleted successfully!", {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar("Failed to delete workspace. Please try again.", {
          variant: "error",
        });
      }
      setOpenDialog(false);
    }
  };

  const handleDeleteCancel = () => {
    setOpenDialog(false);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "20px",
        width: "100%",
      }}
    >
      <Typography
        variant="h4"
        component="h2"
        style={{ color: color.white, marginBottom: "20px" }}
      >
        Workspaces
      </Typography>

      <TableContainer
        sx={{
          width: "70%",
          overflowX: "auto",
          padding: "0 1rem",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: color.white, width: "50%" }}>
                Name
              </TableCell>
              <TableCell style={{ color: color.white, width: "30%" }}>
                Namespace
              </TableCell>
              <TableCell style={{ color: color.white, width: "20%" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workspaces.map((workspace) => (
              <TableRow
                key={workspace.id}
                sx={{
                  backgroundColor: "transparent",
                  "&:hover": { backgroundColor: color.primary_dark },
                }}
              >
                <TableCell
                  style={{
                    color: color.white,
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/workspace/${workspace.id}`)}
                >
                  {workspace.name}
                </TableCell>
                <TableCell style={{ color: color.white }}>
                  {parseNamespaces(workspace.namespaces).join(", ") || "N/A"}
                </TableCell>
                <TableCell style={{ color: color.white }}>
                  <IconButton
                    style={{ color: color.btn_dark }}
                    onClick={() => handleEdit(workspace)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    style={{ color: color.red }}
                    onClick={() => handleDeleteClick(workspace)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Grid item justifyContent="end" width="100%">
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={totalWorkspaces}
            rowsPerPage={10}
            page={page - 1}
            onPageChange={handleChangePage}
            labelRowsPerPage=""
          />
        </Grid>
      </TableContainer>

      <Button
        onClick={() =>
          navigate("/workspace/create", {
            state: { workspace: null },
          })
        }
        sx={{
          marginTop: "1rem",
          backgroundColor: color.btn_dark,
          color: color.white,
          padding: "8px 16px",
          borderRadius: "5px",
          border: "none",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: color.primary_dark,
            color: color.btn_dark,
          },
        }}
      >
        Create New Workspace
      </Button>

      <ConfirmationDialog
        open={openDialog}
        message="Are you sure you want to delete this workspace?"
        workspaceName={selectedWorkspace?.name || ""}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />
    </Grid>
  );
};

export default ViewWorkspaces;
