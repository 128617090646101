import React, { useEffect } from "react";
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import color from "../../Constants/colors";
import BusinessIcon from "@mui/icons-material/Business";
import { CompanyResponse } from "../../Models/company";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";

interface SelectCompanyProps {
  selectedCompanyId: number | "";
  handleSelectChange: (companyId: number) => void;
  companyResponse: CompanyResponse | undefined;
  isEvaluateLoading: boolean;
  onRefetchCompanies: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<CompanyResponse, unknown>>;
}

const SelectCompany: React.FC<SelectCompanyProps> = ({
  selectedCompanyId,
  handleSelectChange,
  companyResponse,
  isEvaluateLoading,
  onRefetchCompanies,
}) => {
  useEffect(() => {
    if (!isEvaluateLoading) {
      onRefetchCompanies();
    }
  }, [isEvaluateLoading, onRefetchCompanies]);

  const handleCardClick = (companyId: number) => {
    handleSelectChange(companyId);
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "2rem",
          alignItems: "center",
          flexDirection: "column",
          width: { xs: "90%", sm: "70%", md: "50%" },
          mt: 5,
        }}
      >
        <Typography
          variant="h3"
          sx={{ color: color.white, marginBottom: "1.5rem" }}
        >
          Select a Company
        </Typography>
        <Typography
          sx={{
            fontSize: "1.2rem",
            textAlign: "center",
            color: color.white,
            opacity: "0.7",
          }}
        >
          In this step, you are required to select a company for which you will
          be conducting the evaluation. This is a crucial choice, as the
          evaluation will be based on the company's data and performance. Make
          sure to carefully review the available options and choose the company
          that best suits your evaluation needs. Once you've made your
          selection, you can proceed to the next steps in the evaluation
          process.
        </Typography>
      </Grid>

      <Grid container spacing={2} justifyContent="center" sx={{ width: "70%" }}>
        {companyResponse?.companies?.map((company) => (
          <Grid item key={company.id} xs={12} sm={6} md={4}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor:
                  selectedCompanyId === company.id
                    ? color.btn_dark
                    : color.white,
                transition: "background-color 0.3s ease",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: color.btn_dark,
                },
                boxShadow: selectedCompanyId === company.id ? 3 : 1,
              }}
              onClick={() => handleCardClick(company.id)}
            >
              <CardActionArea>
                <CardContent sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ flexGrow: 1, textAlign: "left" }}>
                    <Tooltip
                      title={company.name.length > 19 ? company.name : ""}
                      arrow
                    >
                      <Typography
                        variant="h6"
                        className="company-name"
                        sx={{
                          fontSize: "1.5rem",
                          color:
                            selectedCompanyId === company.id
                              ? color.primary_dark
                              : color.primary_dark,
                          fontWeight: "bold",
                        }}
                      >
                        {company.name.length > 19
                          ? `${company.name.slice(0, 19)}...`
                          : company.name}
                      </Typography>
                    </Tooltip>
                  </Box>

                  <BusinessIcon
                    sx={{
                      fontSize: "2.5rem",
                      color: color.primary_dark,
                      marginLeft: "auto",
                    }}
                  />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default SelectCompany;
