import {
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  TextField,
  TableSortLabel,
} from "@mui/material";
import { useState } from "react";
import { useUsers } from "../../Hooks/useUsers";
import color from "../../Constants/colors";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ModelLevel } from "../../Constants/Enums/companyStatus";
import { hasPermission } from "../../helpers/helpers";
import { Features } from "../../Constants/Enums/companyStatus";

const UserPage = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize] = useState(50);
  const [search, setSearch] = useState("");
  const { data: users } = useUsers(page, pageSize, search);
  const [sortColumn, setSortColumn] = useState<
    | "name"
    | "surname"
    | "email"
    | "companies_limit"
    | "daily_limit"
    | "model_level"
    | "workspace"
    | null
  >(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSort = (
    column:
      | "name"
      | "surname"
      | "email"
      | "companies_limit"
      | "daily_limit"
      | "model_level"
      | "workspace"
  ) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const sortedUsers = users?.users
    ? [...users.users].sort((a, b) => {
        if (!sortColumn) return 0;

        let valueA = a[sortColumn];
        let valueB = b[sortColumn];

        if (sortColumn === "workspace") {
          valueA = a.workspace?.name || "";
          valueB = b.workspace?.name || "";
        }

        if (valueA == null) return 1;
        if (valueB == null) return -1;

        return (
          (valueA > valueB ? 1 : valueA < valueB ? -1 : 0) *
          (sortOrder === "asc" ? 1 : -1)
        );
      })
    : [];

  const modelLevelToString = (level: number) => {
    switch (level) {
      case ModelLevel.BASIC:
        return "BASIC";
      case ModelLevel.ENTRY:
        return "ENTRY";
      case ModelLevel.FULL:
        return "FULL";
      default:
        return "Unknown";
    }
  };

  if (!users) {
    return <CircularProgress />;
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      maxWidth="xl"
      margin="0 auto"
    >
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        mb={5}
        height="100px"
      >
        <Grid item>
          <Typography variant="h2" sx={{ color: color.white }}>
            Users
          </Typography>
        </Grid>
        <Grid item ml={3}>
          <Tooltip title="Create new user">
            <IconButton
              onClick={() => {
                navigate("create");
              }}
            >
              <AddCircleOutlineIcon
                sx={{ color: color.btn_dark, fontsize: "100px" }}
              />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item mb={3}>
        <TextField
          label="Search users"
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
          sx={{ width: "300px" }}
        />
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                color: color.btn_dark,
                fontWeight: "bolder",
              }}
            >
              <TableSortLabel
                active={sortColumn === "name"}
                direction={sortColumn === "name" ? sortOrder : "asc"}
                onClick={() => handleSort("name")}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                color: color.btn_dark,
                fontWeight: "bolder",
              }}
            >
              <TableSortLabel
                active={sortColumn === "surname"}
                direction={sortColumn === "surname" ? sortOrder : "asc"}
                onClick={() => handleSort("surname")}
              >
                Surname
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                color: color.btn_dark,
                fontWeight: "bolder",
              }}
            >
              <TableSortLabel
                active={sortColumn === "email"}
                direction={sortColumn === "email" ? sortOrder : "asc"}
                onClick={() => handleSort("email")}
              >
                Email
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                color: color.btn_dark,
                fontWeight: "bolder",
              }}
            >
              <TableSortLabel
                active={sortColumn === "workspace"}
                direction={sortColumn === "workspace" ? sortOrder : "asc"}
                onClick={() => handleSort("workspace")}
              >
                Workspace
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                color: color.btn_dark,
                fontWeight: "bolder",
              }}
            >
              <TableSortLabel
                active={sortColumn === "companies_limit"}
                direction={sortColumn === "companies_limit" ? sortOrder : "asc"}
                onClick={() => handleSort("companies_limit")}
              >
                Companies Limit
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                color: color.btn_dark,
                fontWeight: "bolder",
              }}
            >
              <TableSortLabel
                active={sortColumn === "daily_limit"}
                direction={sortColumn === "daily_limit" ? sortOrder : "asc"}
                onClick={() => handleSort("daily_limit")}
              >
                Requests Limit
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                color: color.btn_dark,
                fontWeight: "bolder",
              }}
            >
              <TableSortLabel
                active={sortColumn === "model_level"}
                direction={sortColumn === "model_level" ? sortOrder : "asc"}
                onClick={() => handleSort("model_level")}
              >
                Model Level
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedUsers.length > 0 ? (
            sortedUsers.map((user) => (
              <TableRow
                key={user.id}
                sx={{
                  "&:hover": { backgroundColor: color.primary_dark },
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/user/${user.id}`)}
              >
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.surname}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.workspace?.name}</TableCell>
                {hasPermission(Features.ORDER_AN_EXPERT) ||
                hasPermission(Features.DUSMANN_IMPORT) ? (
                  <TableCell colSpan={3} align="center"></TableCell>
                ) : (
                  <>
                    <TableCell>{user.companies_limit}</TableCell>
                    <TableCell>{user.daily_limit}</TableCell>
                    <TableCell>
                      {modelLevelToString(user.model_level)}
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={7} align="center">
                No users available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Grid item justifyContent="end" width="100%">
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={users.total}
          rowsPerPage={10}
          page={page - 1}
          onPageChange={handleChangePage}
          labelRowsPerPage=""
        />
      </Grid>
    </Grid>
  );
};

export default UserPage;
