import React, { useEffect } from "react";
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { useCertification } from "../../Hooks/useCertifications";
import { useQuestionListsForEvaluation } from "../../Hooks/useQuestions";
import color from "../../Constants/colors";
import { Features, Steps, UserRole } from "../../Constants/Enums/companyStatus";
import PolicyIcon from "@mui/icons-material/Policy";
import { useSnackbar } from "notistack";
import { hasPermission, hasRole } from "../../helpers/helpers";

interface CategoryAndQuestionFormProps {
  certificationId: string;
  setCertificationId: React.Dispatch<React.SetStateAction<string>>;
  questionListId: string;
  setQuestionListId: React.Dispatch<React.SetStateAction<string>>;
  isEvaluateLoading: boolean;
  selectedModelId: number | "";
  currentStep: number;
}

const CategoryAndQuestionForm: React.FC<CategoryAndQuestionFormProps> = ({
  certificationId,
  setCertificationId,
  questionListId,
  setQuestionListId,
  currentStep,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: certifications,
    isLoading: isCertLoading,
    isError: isCertError,
    error: certError,
  } = useCertification();

  const {
    data: questionLists,
    isLoading: isQLoading,
    isError: isQLError,
    error: qListError,
    refetch,
  } = useQuestionListsForEvaluation(
    certificationId ? Number(certificationId) : undefined
  );

  const isAnyError = isCertError || isQLError;
  const isLoadingErrorCert = isCertLoading;
  const isLoadingErrorQl = isQLoading;

  useEffect(() => {
    if (certificationId && currentStep === Steps.SELECT_QUESTION_LIST) {
      setQuestionListId("");
      refetch();
    }
  }, [certificationId, setQuestionListId, refetch, currentStep]);

  if (isLoadingErrorCert && currentStep === Steps.SELECT_CERTFICATION_CATEGORY)
    return <CircularProgress />;
  if (isLoadingErrorQl && currentStep === Steps.SELECT_QUESTION_LIST)
    return <CircularProgress />;
  if (isAnyError) {
    const errorMessage =
      certError?.message || qListError?.message || "Error loading data.";
    enqueueSnackbar(errorMessage, { variant: "error" });
    return <Typography color="error">{errorMessage}</Typography>;
  }

  const handleCertificationClick = (certificationId: string) => {
    setCertificationId(certificationId);
    setQuestionListId("");
  };

  const handleQuestionListClick = (listId: string) => {
    setQuestionListId(listId);
  };

  return (
    <Grid item sx={{ width: { xs: "90%", sm: "70%", md: "50%" } }}>
      <Grid container spacing={2}>
        {currentStep === Steps.SELECT_CERTFICATION_CATEGORY && (
          <Grid item xs={12}>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "2rem",
                alignItems: "center",
                flexDirection: "column",
                width: { xs: "90%", sm: "70%", md: "100%" },
                mt: 5,
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  textAlign: "center",
                  color: color.white,
                  marginBottom: "1.5rem",
                }}
              >
                Select Certification Category
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  textAlign: "center",
                  color: color.white,
                  opacity: "0.7",
                }}
              >
                Please select the Certification Category under which the
                evaluation will be processed. This category defines the criteria
                and standards for the evaluation, ensuring it aligns with the
                specific requirements of the certification. Once the category is
                selected, the system will apply the relevant evaluation methods
                and guidelines.
              </Typography>
            </Grid>
            <Grid container spacing={2} justifyContent="center">
              {certifications?.map((cert) => (
                <Grid item key={cert.id} xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      minHeight: "90px",
                      justifyContent: "center",
                      backgroundColor:
                        certificationId === cert.id.toString()
                          ? color.btn_dark
                          : color.white,
                      transition: "background-color 0.3s ease",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: color.btn_dark,
                      },
                      boxShadow: certificationId === cert.id.toString() ? 3 : 1,
                    }}
                    onClick={() => handleCertificationClick(cert.id.toString())}
                  >
                    <CardActionArea>
                      <CardContent
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Box sx={{ flexGrow: 1, textAlign: "left" }}>
                          <Tooltip
                            title={cert.name.length > 22 ? cert.name : ""}
                            arrow
                          >
                            <Typography
                              variant="h6"
                              sx={{
                                fontSize: "1rem",
                                color: color.primary_dark,
                                fontWeight: "bold",
                              }}
                            >
                              {cert.name.length > 22
                                ? `${cert.name.slice(0, 22)}...`
                                : cert.name}
                            </Typography>
                          </Tooltip>
                        </Box>

                        {cert.digital_performance && (
                          <PolicyIcon
                            sx={{
                              fontSize: "2.5rem",
                              color: color.primary_dark,
                            }}
                          />
                        )}
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}

        {currentStep === Steps.SELECT_QUESTION_LIST &&
          (hasPermission(Features.QUESTION_LISTS) ||
            hasRole(UserRole.ADMIN || hasPermission(Features.EVALUATION))) && (
            <Grid item xs={12}>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "2rem",
                  alignItems: "center",
                  flexDirection: "column",
                  width: { xs: "90%", sm: "70%", md: "100%" },
                  mt: 5,
                }}
              >
                <Typography
                  variant="h3"
                  sx={{ color: color.white, marginBottom: "1.5rem" }}
                >
                  Select Question List
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: "center",
                    color: color.white,
                    opacity: "0.7",
                  }}
                >
                  Please select the Question List through which the evaluation
                  will be conducted. This list defines the set of questions and
                  criteria that will guide the evaluation process, ensuring it
                  covers all necessary aspects. Once the list is selected, the
                  system will use it to assess the data accordingly.
                </Typography>
              </Grid>
              <Grid container spacing={2} justifyContent="center">
                {questionLists?.question_lists.map((list) => (
                  <Grid item key={list.id} xs={12} sm={6} md={4}>
                    <Card
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        minHeight: "90px",
                        justifyContent: "center",
                        backgroundColor:
                          questionListId === list.id.toString()
                            ? color.btn_dark
                            : color.white,
                        transition: "background-color 0.3s ease",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: color.btn_dark,
                        },
                        boxShadow:
                          questionListId === list.id.toString() ? 3 : 1,
                      }}
                      onClick={() =>
                        handleQuestionListClick(list.id.toString())
                      }
                    >
                      <CardActionArea>
                        <CardContent
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Box sx={{ flexGrow: 1, textAlign: "left" }}>
                            <Tooltip
                              title={list.name.length > 22 ? list.name : ""}
                              arrow
                            >
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "1rem",
                                  color: color.primary_dark,
                                  fontWeight: "bold",
                                }}
                              >
                                {list.name.length > 22
                                  ? `${list.name.slice(0, 22)}...`
                                  : list.name}
                              </Typography>
                            </Tooltip>
                            <Typography
                              variant="body2"
                              sx={{
                                color: color.primary_light,
                                fontSize: "0.8rem",
                                fontStyle: "italic",
                              }}
                            >
                              Created by: {list.created_by.name}
                            </Typography>
                          </Box>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
      </Grid>
    </Grid>
  );
};

export default CategoryAndQuestionForm;
