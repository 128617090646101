import { KnowledgeBaseStatus } from "../../Constants/Enums/companyStatus";

export const statusToStringKnowledgeBase = (status: number): string => {
  switch (status) {
    case KnowledgeBaseStatus.WAITING:
      return "Waiting";
    case KnowledgeBaseStatus.IN_PROGRESS:
      return "Processing";
    case KnowledgeBaseStatus.PROCESSED:
      return "Completed";
    case KnowledgeBaseStatus.ERROR:
      return "Error";
    case KnowledgeBaseStatus.TIME_LIMIT_EXCEEDED:
      return "Time limit exceeded";
    default:
      return "Unknown";
  }
};
