import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import color from "../../Constants/colors";
import { useNavigate } from "react-router-dom";
import theme from "../../Constants/theme";
import {
  useDeleteKnowledgeBase,
  useKnowledgeList,
  useSingleKnowledge,
} from "../../Hooks/useKnowledge";
import { GetAllKnowledgeBasesResponse } from "../../Models/knowledge";
import { useSortableData } from "../../Hooks/useSortableData";
import { useState } from "react";
import KnowledgeBaseModal from "./KnowledgeBaseModal ";
import ConfirmationDialog from "../UI/dialogs/ConfirmationDialog";
import { statusToStringKnowledgeBase } from "../../Services/Functions/statusToStringKnowledgeBase";

const KnowledgeBaseAll = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [selectedKnowledgeBaseId, setSelectedKnowledgeBaseId] = useState<
    number | null
  >(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate: deleteKnowledgeBase } = useDeleteKnowledgeBase();

  const handleModalClose = () => setIsModalOpen(false);

  const handleKnowledgeBaseName = (knowledgeBaseId: number) => {
    setSelectedKnowledgeBaseId(knowledgeBaseId);
    setIsModalOpen(true);
  };

  const handleConfirmationDialogClose = (confirmed: boolean) => {
    setConfirmationDialogOpen(false);
    if (confirmed && selectedKnowledgeBaseId) {
      deleteKnowledgeBase(selectedKnowledgeBaseId);
    }
  };

  const handleEditKnowledgeBase = (knowledgeBaseId: number) => {
    navigate(`/knowledge_base/edit/${knowledgeBaseId}`);
  };

  const { data, isLoading, isError } = useKnowledgeList();

  const { sortedItems, sortField, sortOrder, handleSort } =
    useSortableData<GetAllKnowledgeBasesResponse>(data ?? [], "name");

  const { data: selectedKnowledgeBase, isLoading: isKnowledgeBaseLoading } =
    useSingleKnowledge(selectedKnowledgeBaseId ?? 0);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (isError) {
    return <div>Error: Failed to fetch knowledge base list</div>;
  }

  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "20px",
        width: "100%",
      }}
    >
      <Typography
        variant="h4"
        component="h2"
        style={{ color: color.white, marginBottom: "20px" }}
      >
        Knowledge Bases
      </Typography>

      <TableContainer
        sx={{
          width: matches ? "90%" : "30%",
          overflowX: "auto",
          padding: matches ? "0 1rem" : "0",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ color: color.white, cursor: "pointer", width: "50%" }}
                onClick={() => handleSort("name")}
              >
                Name
                {sortField === "name" && (
                  <TableSortLabel active direction={sortOrder} />
                )}
              </TableCell>
              <TableCell style={{ color: color.white, width: "30%" }}>
                Status
              </TableCell>
              <TableCell style={{ color: color.white, width: "20%" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedItems.map((item) => (
              <TableRow
                key={item.id}
                sx={{
                  backgroundColor: "transparent",
                  "&:hover": { backgroundColor: color.primary_dark },
                }}
              >
                <TableCell
                  style={{
                    color: color.white,
                    fontWeight: "normal",
                    cursor: "pointer",
                  }}
                  onClick={() => handleKnowledgeBaseName(item.id)}
                >
                  <span>{item.name}</span>
                </TableCell>
                <TableCell style={{ color: color.white }}>
                  {statusToStringKnowledgeBase(item.status)}
                </TableCell>
                <TableCell>
                  <Tooltip title={"Edit"}>
                    <span>
                      <IconButton
                        style={{
                          color: color.btn_dark,
                        }}
                        onClick={() => handleEditKnowledgeBase(item.id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={"Delete"}>
                    <span>
                      <IconButton
                        onClick={() => {
                          setSelectedKnowledgeBaseId(item.id);
                          setConfirmationDialogOpen(true);
                        }}
                        style={{
                          color: color.red,
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        title="Delete Knowledge Base"
        description="confirmation to delete a Knowledge Base"
        content="Are you sure you want to delete this Knowledge Base?"
        actions={[
          {
            label: "No",
            onClick: () => handleConfirmationDialogClose(false),
          },
          {
            label: "Yes",
            onClick: () => handleConfirmationDialogClose(true),
          },
        ]}
      />

      <KnowledgeBaseModal
        open={isModalOpen}
        onClose={handleModalClose}
        knowledgeBase={selectedKnowledgeBase ?? null}
        isLoading={isKnowledgeBaseLoading}
      />

      <Button
        onClick={() => navigate("/knowledge_base/create")}
        sx={{
          marginTop: "2rem",
          marginBottom: "2rem",
          backgroundColor: color.btn_dark,
          color: color.white,
          padding: "10px 20px",
          borderRadius: "5px",
          border: "none",
          cursor: "pointer",
          width: "fit-content",
          "&:hover": {
            backgroundColor: color.primary_dark,
            color: color.btn_dark,
          },
        }}
      >
        Create New Knowledge Base
      </Button>
    </Grid>
  );
};

export default KnowledgeBaseAll;
