import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import color from "../../Constants/colors";

interface ConfirmationDialogProps {
  open: boolean;
  message: string;
  workspaceName: string;
  onClose: () => void;
  onConfirm: (workspaceName: string) => Promise<void>;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  message,
  workspaceName,
  onClose,
  onConfirm,
}) => {
  const [enteredName, setEnteredName] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredName(event.target.value);
    setError(false);
  };

  const handleConfirm = async () => {
    if (enteredName === workspaceName) {
      setLoading(true);
      try {
        await onConfirm(enteredName);
      } finally {
        setLoading(false);
      }
    } else {
      setError(true);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={loading ? undefined : onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogContent sx={{ padding: "20px", color: color.primary_dark }}>
        <DialogContentText sx={{ color: color.primary_dark }}>
          {message}
        </DialogContentText>
        <TextField
          label="Enter Workspace Name"
          variant="outlined"
          fullWidth
          value={enteredName}
          onChange={handleChange}
          error={error}
          helperText={error ? "Names do not match" : ""}
          disabled={loading}
          sx={{
            marginTop: "15px",
            "& .MuiInputBase-input": {
              color: color.primary_dark,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: color.btn_dark,
              },
              "&:hover fieldset": {
                borderColor: color.btn_dark,
              },
              "&.Mui-focused fieldset": {
                borderColor: color.btn_dark,
              },
            },
            "& .MuiInputLabel-root": {
              color: color.primary_dark,
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: color.primary_dark,
            },
            "& .css-1ruifsz-MuiFormLabel-root-MuiInputLabel-root[data-shrink='false']":
              {
                color: color.primary_light,
              },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
            setEnteredName("");
          }}
          disabled={loading}
          sx={{
            backgroundColor: color.btn_dark,
            color: color.white,
            marginBottom: "10px",
            marginTop: "10px",
            "&:hover": {
              backgroundColor: color.primary_dark,
              color: color.btn_dark,
            },
          }}
        >
          No
        </Button>
        <Button
          onClick={() => {
            handleConfirm();
            setEnteredName("");
          }}
          disabled={enteredName !== workspaceName || loading}
          sx={{
            backgroundColor: color.btn_dark,
            color: color.white,
            marginBottom: "10px",
            marginTop: "10px",
            "&:hover": {
              backgroundColor: color.primary_dark,
              color: color.btn_dark,
            },
          }}
        >
          {loading ? (
            <CircularProgress size={24} sx={{ color: color.white }} />
          ) : (
            "Yes"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
