import {
  Container,
  Grid,
  TextField,
  Button,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { createCompany } from "../../Services/Https/company";
import { useNavigate } from "react-router-dom";
import color from "../../Constants/colors";
import { enqueueSnackbar } from "notistack";
import { handleAxiosError } from "../../Services/Https/errorHandler";

const CreateCompanyForm: React.FC = () => {
  const [companyName, setCompanyName] = useState<string>("");
  const [companyAbout, setCompanyAbout] = useState<string>("");
  const [industry, setIndustry] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleCreateCompany = async () => {
    if (companyName.length > 40) {
      enqueueSnackbar("Company Name should be less than 40 characters.", {
        variant: "warning",
      });
      return;
    }

    setLoading(true);
    try {
      const response = await createCompany(
        companyName,
        companyAbout,
        industry,
        location
      );
      enqueueSnackbar("Company created successfully", { variant: "success" });

      const companyId = response.data.company.id;
      navigate(`/company/${companyId}`);
    } catch (error: any) {
      if (error.response && error.response.status === 418) {
        enqueueSnackbar(
          `You have reached the maximum number of companies allowed. `,
          { variant: "error" }
        );
      } else {
        const errorMessage = handleAxiosError(error);
        enqueueSnackbar(`Error creating company: ${errorMessage}`, {
          variant: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const isFormComplete = companyName && companyAbout;

  return (
    <Container>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12}>
          <TextField
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            label="Company Name"
            variant="outlined"
            fullWidth
            disabled={loading}
            InputLabelProps={{
              style: { color: "white" },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={industry}
            onChange={(e) => setIndustry(e.target.value)}
            label="Industry"
            variant="outlined"
            fullWidth
            disabled={loading}
            InputLabelProps={{
              style: { color: "white" },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            label="Location"
            variant="outlined"
            fullWidth
            disabled={loading}
            InputLabelProps={{
              style: { color: "white" },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={companyAbout}
            onChange={(e) => setCompanyAbout(e.target.value)}
            label="About"
            multiline
            fullWidth
            variant="outlined"
            margin="normal"
            disabled={loading}
            InputLabelProps={{
              style: { color: "white" },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {isFormComplete ? (
            <Button
              variant="contained"
              onClick={handleCreateCompany}
              disabled={loading}
              sx={{
                marginTop: 2,
                backgroundColor: color.btn_dark,
                color: color.white,
                "&:hover": {
                  backgroundColor: color.primary_dark,
                  color: color.btn_dark,
                },
              }}
            >
              {loading ? <CircularProgress size={24} /> : "Create the Company"}
            </Button>
          ) : (
            <Tooltip
              title={
                "Please add Company Name and About (Location and Industry are optional)"
              }
            >
              <span>
                <Button
                  variant="contained"
                  onClick={handleCreateCompany}
                  disabled
                  sx={{
                    marginTop: 2,
                    backgroundColor: color.btn_dark,
                    color: color.white,
                    "&:hover": {
                      backgroundColor: color.primary_dark,
                      color: color.btn_dark,
                    },
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Create the Company"
                  )}
                </Button>
              </span>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default CreateCompanyForm;
