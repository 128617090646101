import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Divider,
  Grid,
  CircularProgress,
  Typography,
  IconButton,
} from "@mui/material";
import { useSnackbar } from "notistack";
import "../../Styles/HomePage.css";
import { fetchEvaluate } from "../../Services/Https/llm";
import { User } from "../../Models/user";
import {
  Features,
  ModelLevel,
  UserRole,
  Steps,
} from "../../Constants/Enums/companyStatus";
import color from "../../Constants/colors";
import { useCheckProgress, useCompanies } from "../../Hooks/useCompanies";
import { CompanyStatus } from "../../Constants/Enums/companyStatus";
import { CompanyInfo } from "../../Models/company";
import StepperComponent from "./StepperComponent";
import SelectEvaluationModel from "./SelectEvaluationModel";
import CategoryAndQuestionForm from "./CategoryAndQustionForm";
import EvaluateButton from "./EvaluateButton";
import SelectCompany from "./SelectCompany";
import LanguageSelect from "./LanguageSelect";
import { useEvaluationModel } from "../../Hooks/useEvaluation";
import { evaluationModel } from "../../Models/evaluationModel";
import { useCompanyContext } from "../../contexts/SelectedCompanyContext";
import { hasRole, hasPermission } from "../../helpers/helpers";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

interface HomeComponentProps {
  user: User | undefined;
}

const HomeComponent: React.FC<HomeComponentProps> = ({ user }) => {
  const hasQuestionsPermission =
    hasPermission(Features.QUESTION_LISTS) ||
    hasRole(UserRole.ADMIN || hasPermission(Features.EVALUATION));
  const steps = [
    "Company",
    "Evaluation Model",
    "Certification Category",
    ...(hasQuestionsPermission ? ["Question List"] : []),
    "Evaluation",
  ];
  const [currentStep, setCurrentStep] = useState(0);
  const [alanExpertEnabled, setAlanExpertEnabled] = useState<boolean>(false);
  const [company, setCompany] = useState<CompanyInfo>();
  const [selectedModelId, setSelectedModelId] = useState<number | "">("");
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isEvaluateLoading, setIsEvaluateLoading] = useState(false);
  const [languageId, setLanguageId] = useState<number>(0);
  const [chunkSize] = useState(3000);
  const [chunkOverlap] = useState(700);
  const [certificationId, setCertificationId] = useState("");
  const [questionListId, setQuestionListId] = useState("");
  const [modelOptions, setModelOptions] = useState<evaluationModel[]>([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedCompanyId, setSelectedCompanyId } = useCompanyContext();

  const { data: useEvaluationModelData, isLoading: useEvaluationModelLoading } =
    useEvaluationModel();

  const { data: companyResponse, refetch: refetchCompanies } = useCompanies(
    1,
    20,
    "name",
    "asc",
    ""
  );

  const { data: checkProgressData, refetch: refetchCheckProgressData } =
    useCheckProgress();

  const evaluationId = checkProgressData?.company?.id?.toString();

  useEffect(() => {
    setSelectedModelId("");
    setCertificationId("");
    setQuestionListId("");
    setSelectedCompanyId("");
    setIsEvaluateLoading(false);
  }, [
    setSelectedModelId,
    setCertificationId,
    setQuestionListId,
    setSelectedCompanyId,
    setIsEvaluateLoading,
  ]);

  useEffect(() => {
    setAlanExpertEnabled(hasRole(UserRole.ADMIN));
  }, []);

  useEffect(() => {
    if (useEvaluationModelData && user) {
      const userLevel = user.model_level as ModelLevel;
      const userModelAccess = useEvaluationModelData.model_access.find(
        (level) => level.level_id === userLevel
      );

      setModelOptions(userModelAccess ? userModelAccess.models : []);
    }
  }, [useEvaluationModelData, user]);
  useEffect(() => {
    const hasRedirectedKey = `hasRedirected_${evaluationId}`;

    sessionStorage.removeItem(hasRedirectedKey);

    if (checkProgressData?.in_progress) {
      sessionStorage.setItem(hasRedirectedKey, "true");
      navigate(`/evaluation_in_progress/${evaluationId}`);
    }

    if (!checkProgressData?.in_progress) {
      sessionStorage.removeItem(hasRedirectedKey);
    }
  }, [checkProgressData?.in_progress, evaluationId, navigate]);

  const handleNextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const handleToggleChange = (newValue: boolean) => {
    setAlanExpertEnabled(newValue);
  };

  const handleSelectChange = (companyId: number) => {
    setSelectedCompanyId(companyId);
  };

  const handleModelChange = (modelId: number) => {
    setSelectedModelId(modelId);
  };

  const handleLanguageChange = (id: number) => {
    setLanguageId(id);
  };

  const handleEvaluateClick = async (
    questionListId: number,
    certificationId: number
  ): Promise<void> => {
    setCompany(undefined);
    setIsButtonClicked(true);
    setIsEvaluateLoading(true);

    if (selectedModelId !== "" && selectedCompanyId) {
      try {
        await fetchEvaluate(
          selectedCompanyId,
          selectedModelId,
          chunkSize,
          chunkOverlap,
          questionListId,
          certificationId,
          languageId,
          alanExpertEnabled
        );
        setLanguageId(languageId);
        refetchCheckProgressData();
      } catch (error: any) {
        setIsEvaluateLoading(false);

        enqueueSnackbar(error.message, { variant: "error" });
      }
    } else {
      setIsEvaluateLoading(false);
      enqueueSnackbar(
        "Please select both a company and a model before evaluating.",
        { variant: "warning" }
      );
    }
  };

  if (!user) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid
      container
      sx={{
        width: "100vw",
        margin: "0 auto",
        pt: "2vh",
      }}
      alignContent="center"
      justifyContent="center"
      direction="column"
      alignItems="center"
    >
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography variant="h2" sx={{ color: color.white }}>
          Evaluate Your Company
        </Typography>
      </Grid>
      <StepperComponent currentStep={currentStep} steps={steps} />
      {currentStep === Steps.SELECT_COMPANY && (
        <SelectCompany
          selectedCompanyId={selectedCompanyId}
          handleSelectChange={handleSelectChange}
          companyResponse={companyResponse}
          isEvaluateLoading={isEvaluateLoading}
          onRefetchCompanies={refetchCompanies}
        />
      )}
      {currentStep === Steps.SELECT_MODEL && (
        <SelectEvaluationModel
          selectedModelId={selectedModelId}
          modelOptions={modelOptions}
          handleModelChange={handleModelChange}
          isEvaluateLoading={isEvaluateLoading}
          useEvaluationModelLoading={useEvaluationModelLoading}
        />
      )}
      {(currentStep === Steps.SELECT_CERTFICATION_CATEGORY ||
        currentStep === Steps.SELECT_QUESTION_LIST) && (
        <CategoryAndQuestionForm
          certificationId={certificationId}
          setCertificationId={setCertificationId}
          questionListId={questionListId}
          setQuestionListId={setQuestionListId}
          isEvaluateLoading={isEvaluateLoading}
          selectedModelId={selectedModelId}
          currentStep={currentStep}
        />
      )}
      {hasQuestionsPermission
        ? currentStep === Steps.EVALUATION && (
            <>
              {(!company ||
                company.status === CompanyStatus.ERROR ||
                company.status === CompanyStatus.TIME_LIMIT_EXCEEDED) && (
                <EvaluateButton
                  handleEvaluateClick={handleEvaluateClick}
                  companyResponse={companyResponse}
                  modelOptions={modelOptions}
                  selectedCompanyId={selectedCompanyId}
                  selectedModelId={selectedModelId}
                  certificationId={certificationId}
                  questionListId={questionListId}
                  isButtonClicked={isButtonClicked}
                  hasQuestionPermisson={hasQuestionsPermission}
                />
              )}

              <Grid item width="50%">
                <Divider
                  sx={{
                    margin: "auto",
                    width: "100%",
                    borderColor: color.accent,
                  }}
                />
                <LanguageSelect
                  languageId={languageId}
                  onLanguageChange={handleLanguageChange}
                  onToggleAlanExpertChange={handleToggleChange}
                  showToggleAlanExpert={hasRole(UserRole.ADMIN)}
                />
              </Grid>
            </>
          )
        : currentStep === Steps.SELECT_QUESTION_LIST && (
            <>
              {(!company ||
                company.status === CompanyStatus.ERROR ||
                company.status === CompanyStatus.TIME_LIMIT_EXCEEDED) && (
                <EvaluateButton
                  handleEvaluateClick={handleEvaluateClick}
                  companyResponse={companyResponse}
                  modelOptions={modelOptions}
                  selectedCompanyId={selectedCompanyId}
                  selectedModelId={selectedModelId}
                  certificationId={certificationId}
                  questionListId={questionListId}
                  isButtonClicked={isButtonClicked}
                  hasQuestionPermisson={hasQuestionsPermission}
                />
              )}

              <Grid item width="50%">
                <Divider
                  sx={{
                    margin: "auto",
                    width: "100%",
                    borderColor: color.accent,
                  }}
                />
                <LanguageSelect
                  languageId={languageId}
                  onLanguageChange={handleLanguageChange}
                  onToggleAlanExpertChange={handleToggleChange}
                  showToggleAlanExpert={hasRole(UserRole.ADMIN)}
                />
              </Grid>
            </>
          )}
      <Grid
        item
        sx={{
          mt: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={handlePreviousStep}
          disabled={currentStep === Steps.SELECT_COMPANY}
          sx={{
            color: color.btn_dark,
            marginRight: "5rem",
          }}
        >
          <ArrowBackIosIcon />
          <Typography
            sx={{
              color:
                currentStep === Steps.SELECT_COMPANY
                  ? color.primary_light
                  : color.white,
              marginLeft: 1,
            }}
          >
            Previous
          </Typography>
        </IconButton>
        <IconButton
          onClick={handleNextStep}
          disabled={
            (currentStep === Steps.SELECT_COMPANY &&
              selectedCompanyId === "") ||
            (currentStep === Steps.SELECT_MODEL && selectedModelId === "") ||
            (currentStep === Steps.SELECT_CERTFICATION_CATEGORY &&
              certificationId === "") ||
            (currentStep === Steps.SELECT_QUESTION_LIST &&
              questionListId === "") ||
            currentStep === steps.length - 1
          }
          sx={{
            color:
              (currentStep === Steps.SELECT_COMPANY &&
                selectedCompanyId === "") ||
              (currentStep === Steps.SELECT_MODEL && selectedModelId === "") ||
              (currentStep === Steps.SELECT_CERTFICATION_CATEGORY &&
                certificationId === "") ||
              (currentStep === Steps.SELECT_QUESTION_LIST &&
                questionListId === "")
                ? color.primary_light
                : color.btn_dark,
          }}
        >
          <Typography
            sx={{
              color:
                (currentStep === Steps.SELECT_COMPANY &&
                  selectedCompanyId === "") ||
                (currentStep === Steps.SELECT_MODEL &&
                  selectedModelId === "") ||
                (currentStep === Steps.SELECT_CERTFICATION_CATEGORY &&
                  certificationId === "") ||
                (currentStep === Steps.SELECT_QUESTION_LIST &&
                  questionListId === "") ||
                currentStep === steps.length - 1
                  ? color.primary_light
                  : color.white,
              marginRight: 1,
            }}
          >
            Next
          </Typography>
          <ArrowBackIosIcon sx={{ transform: "rotate(180deg)" }} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default HomeComponent;
