import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import color from "../../Constants/colors";
import Circle from "./Circle";
import {
  CompanyStatus,
  Languages,
  ModelLevel,
} from "../../Constants/Enums/companyStatus";
import InfoSection from "./InfoSection";
import CheckIcon from "@mui/icons-material/Check";
import RotatingCachedIcon from "./RotatingCachedIcon";
import { useEvaluationModel } from "../../Hooks/useEvaluation";
import { useCheckProgress, useCompanyStatus } from "../../Hooks/useCompanies";
import { useUser } from "../../Hooks/useUsers";
import { useContext, useEffect, useState } from "react";
import { evaluationModel } from "../../Models/evaluationModel";
import { useNavigate } from "react-router-dom";
import Loader from "../UI/Loader";
import { SelectedCompanyContext } from "../../contexts/SelectedCompanyContext";

interface EvaluationProgress {
  id: CompanyStatus;
  name: string;
}

const evaluationProgress: EvaluationProgress[] = [
  {
    id: CompanyStatus.EVALUATION_IN_PROGRESS,
    name: "Evaluation request processing",
  },
  {
    id: CompanyStatus.FETCHING_COMPANY_DETAILS,
    name: "Reading company details",
  },
  {
    id: CompanyStatus.PROCESSING_COMPANY_INFORMATIONS,
    name: "Processing information",
  },
  { id: CompanyStatus.GRADING_COMPANY, name: "Grading company" },
];

const EvaluationInProgress = () => {
  const [modelOptions, setModelOptions] = useState<evaluationModel[]>([]);
  const navigate = useNavigate();
  const { selectedCompanyId } = useContext(SelectedCompanyContext);
  const { data: user } = useUser();

  const { data: useEvaluationModelData } = useEvaluationModel();

  const { data: checkProgressData, isLoading: checkProgressLoading } =
    useCheckProgress();

  const evaluationId = checkProgressData?.company?.id?.toString();
  const { data: evaluationStatus } = useCompanyStatus(evaluationId);

  useEffect(() => {
    if (useEvaluationModelData && user) {
      const userLevel = user.model_level as ModelLevel;
      const userModelAccess = useEvaluationModelData.model_access.find(
        (level) => level.level_id === userLevel
      );

      setModelOptions(userModelAccess ? userModelAccess.models : []);
    }
  }, [useEvaluationModelData, user]);

  useEffect(() => {
    if (
      Number(evaluationStatus?.status) === CompanyStatus.ERROR ||
      Number(evaluationStatus?.status) === CompanyStatus.TIME_LIMIT_EXCEEDED
    ) {
      navigate("/evaluation_error");
    }
    if (
      (Number(evaluationStatus?.status) === CompanyStatus.EVALUATED ||
        Number(evaluationStatus?.status) === CompanyStatus.NEEDS_MORE_INFO) &&
      selectedCompanyId
    ) {
      navigate(`/company/${selectedCompanyId}/add`);
    }
  }, [
    evaluationStatus?.status,
    checkProgressData?.in_progress,
    navigate,
    evaluationId,
    selectedCompanyId,
  ]);

  useEffect(() => {
    if (!checkProgressData?.company) {
      navigate(`/company/${selectedCompanyId}`);
    }
  }, [selectedCompanyId, navigate, checkProgressData]);

  if (checkProgressLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  const model_id =
    checkProgressData?.company?.last_evaluation_request?.model_id;
  const model = modelOptions.find((m) => m.model_id === model_id);
  const modelName = model?.model_name;

  const certificationCategoryName =
    checkProgressData?.company?.last_evaluation_request?.question_list
      ?.category;

  const questionListName =
    checkProgressData?.company?.last_evaluation_request?.question_list?.name;

  const companyName = checkProgressData?.company?.name;

  const evaluationLanguage = checkProgressData?.evaluation?.language;

  const evaluationLanguageName =
    Languages[evaluationLanguage as unknown as keyof typeof Languages];

  const InfoSections = [
    { title: "Company name:", value: companyName },
    { title: "Model name:", value: modelName },
    { title: "Certification category name:", value: certificationCategoryName },
    { title: "Question list name:", value: questionListName },
    { title: "Evaluation language:", value: evaluationLanguageName },
  ];

  return (
    <Box sx={{ padding: 2 }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Grid item>
          <Typography
            variant="h6"
            sx={{ color: color.white, textAlign: "center", marginBottom: 3 }}
          >
            Evaluation in progress. You can leave the page, we will notify you
            when it's done.
          </Typography>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          {evaluationStatus &&
            evaluationProgress.map((progress, index) => {
              const isColored = progress.id <= evaluationStatus.status;
              return (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  lg={2}
                  key={index}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Circle colored={isColored} number={progress.id}>
                    {evaluationStatus.status > progress.id ? (
                      <CheckIcon fontSize="large" />
                    ) : progress.id === evaluationStatus.status ? (
                      <RotatingCachedIcon fontSize="large" />
                    ) : (
                      <Typography variant="h5" sx={{ color: color.white }}>
                        {index + 1}
                      </Typography>
                    )}
                  </Circle>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: color.white,
                      textAlign: "center",
                      marginTop: 1,
                      marginBottom: 1,
                      minHeight: "3rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {progress.name}
                  </Typography>
                </Grid>
              );
            })}
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{ maxWidth: 900, width: "100%" }}
        >
          {InfoSections.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              key={index}
              display="flex"
              justifyContent="center"
            >
              {item.value && (
                <InfoSection title={item.title} value={String(item.value)} />
              )}
            </Grid>
          ))}
        </Grid>
        <Grid item>
          <Loader
            src="/orange_loader.gif"
            message="Evaluation in progress..."
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EvaluationInProgress;
